import React, { useCallback, useEffect, useState } from 'react';

export interface UseValueOptions<T> {
  onChange?: React.ChangeEventHandler<T>;
  value?: string;
}

export const useValue = <
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>({
  onChange,
  value: valueProp = '',
}: UseValueOptions<T>): [string, React.ChangeEventHandler<T>] => {
  const [value, setValue] = useState(valueProp);
  const handleChange = useCallback(
    (e: React.ChangeEvent<T>): void => {
      setValue(e.currentTarget.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );
  useEffect((): void => {
    if (valueProp !== value) {
      setValue(valueProp);
    }
  }, [valueProp]);
  return [value, handleChange];
};
