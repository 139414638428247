/**
 * Utility function for applying class names.
 * @param input Class name string(s), arrays, or maps.
 */
export const className = (
  ...input: Array<string | string[] | boolean | null | undefined>
): string =>
  input
    .reduce((curr: string[], val) => {
      if (Array.isArray(val)) {
        val.forEach(v => curr.push(v));
      } else if (typeof val === 'string') {
        curr.push(val.trim());
      }
      return curr;
    }, [])
    .join(' ');
