import {
  TypographyColor,
  TypographyVariant,
  assertTheme,
  bem,
  cx,
  interpolateTextColor,
  useTheme,
} from '@mint/core';
import { CLASS_NAME } from './constants';
import React from 'react';
import { TypographyProps } from './typography-props';
import { interpolateFontBreakpointStyles } from './interpolate-font-breakpoint-styles';
import { interpolateFontStyles } from './interpolate-font-styles';
import styled from 'styled-components';

// ref: https://zellwk.com/blog/why-vertical-rhythms/
// ref: https://zellwk.com/blog/responsive-vertical-rhythm/
// ref: https://zellwk.com/blog/wrong-about-vertical-rhythm/

const HEURISTIC_COMPONENT_MAP: {
  [K in TypographyVariant]: keyof React.ReactHTML;
} = {
  body: 'p',
  button: 'span',
  caption: 'p',
  control: 'span',
  dense: 'span',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  helper: 'div',
  overline: 'h6',
  subtitle: 'h6',
};

const Wrapper = React.forwardRef<any, TypographyProps>(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      color,
      component,
      variant = 'body',
      ...rest
    } = props;
    return React.createElement(
      component || HEURISTIC_COMPONENT_MAP[variant],
      {
        className: cx(className, CLASS_NAME, bem.modifier(CLASS_NAME, variant)),
        ...rest,
      },
      children,
    );
  },
);

Wrapper.displayName = 'Typography';

export const Typography = styled(Wrapper)`
  ${interpolateTextColor(
    (props: TypographyProps): TypographyColor | undefined => props.color,
  )}
  ${interpolateFontStyles}
  ${interpolateFontBreakpointStyles}
`;

Typography.defaultProps = {
  color: 'text.primary',
  variant: 'body',
};
