import { Breakpoint, assertTheme, rvr, rvrCustomProperty } from '@mint/core';
import { TypographyProps } from './typography-props';

const OVERLINE_DIVISOR = 2;

const fontBreakpointStyles = (
  props: TypographyProps,
  breakpoint: Breakpoint,
): string => {
  const { variant = 'body' } = props;

  const theme = assertTheme(props);
  const media = theme.breakpoints.only(breakpoint);
  const font = theme.typography[variant];
  const vars = theme.vars.typography[variant];

  return `
    ${media} {
      font-size: ${font.fontSize[breakpoint]};
      font-size: var(${vars.fontSize[breakpoint]}, ${
    font.fontSize[breakpoint]
  });

      * + & {
        margin-top: ${rvr(theme, variant, breakpoint)};
        margin-top: ${rvrCustomProperty(theme, variant, breakpoint)};
      }

      /* Special case styling if this element is preceded by an overline. */
      .${`${theme.name}-typography--overline`} + & {
        margin-top: ${rvr(
          theme,
          variant,
          breakpoint,
          'overline',
          OVERLINE_DIVISOR,
        )};
        margin-top: ${rvrCustomProperty(
          theme,
          variant,
          breakpoint,
          'overline',
          OVERLINE_DIVISOR,
        )};
      }
    }
  `;
};

export const interpolateFontBreakpointStyles = (
  props: TypographyProps,
): string => {
  return assertTheme(props)
    .breakpoints.names.map((breakpoint): string =>
      fontBreakpointStyles(props, breakpoint as Breakpoint),
    )
    .join('\n');
};
