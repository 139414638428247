import { invariant } from './invariant';

/**
 * Use invariant() to assert state which your program assumes to be true.
 * @param prefix The message prefix.
 */
export const prefixed = (prefix: string): typeof invariant => (
  condition: any,
  format: string,
  ...args: any[]
) => {
  try {
    invariant(condition, `${prefix}: ${format}`, ...args);
  } catch (e) {
    // Adjust the stack to remove the prefix factory function.
    const stack = e.stack.split(/\n/g);
    stack.splice(1, 1);
    e.stack = stack.join('\n');
    throw e;
  }
};
