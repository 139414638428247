import React, { useCallback, useState } from 'react';

export interface BindUseFocus<T> {
  onFocus: React.FocusEventHandler<T>;
  onBlur: React.FocusEventHandler<T>;
}

export type UseFocusOptions<T> = Partial<BindUseFocus<T>>;

export const useFocus = <T>({
  onFocus,
  onBlur,
}: UseFocusOptions<T>): [boolean, BindUseFocus<T>] => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback(
    (e: React.FocusEvent<T>): void => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<T>): void => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  return [
    focused,
    {
      onBlur: handleBlur,
      onFocus: handleFocus,
    },
  ];
};
