/*
 * Highly inspired by Facebook's invariant(), and the many clones available.
 * Original copyright (c) 2013-present, Facebook, Inc.
 */

const createInvariantError = (format: string | undefined, ...args: any[]) => {
  let error: Error;
  if (!format) {
    error = new Error(
      'Minified exception occurred; use the non-minified dev environment ' +
        'for the full error message and additional helpful warnings.',
    );
  } else {
    let argIndex = 0;
    error = new Error(format.replace(/%s/g, () => args[argIndex++]));
  }
  // Pop the stack frames for invariant functions themselves.
  const stack = error.stack
    ? error.stack.split(/\n/g)
    : /* istanbul ignore next */ [];
  stack.splice(1, 2);
  error.stack = stack.join('\n');
  error.name = 'Invariant Violation';
  return error;
};

/**
 * Use invariant() to assert state which your program assumes to be true.
 * @param condition The condition to check.
 * @param format sprintf style format string which only accepts %s formatters.
 * @param args sprint style format args.
 */
export const factory = (env: string) => (
  condition: any,
  format: string,
  ...args: any[]
) => {
  if (env !== 'production' && format === undefined) {
    throw new Error('invariant() requires an error message argument.');
  }
  if (!condition) {
    throw createInvariantError(format, ...args);
  }
};

export const invariant = factory(
  (process && process.env && process.env.NODE_ENV) || 'develop',
);
